import icon512 from "../assets/favicon-512x512.png";

import { appConfig } from "../env";

import {MdGeneratingTokens} from 'react-icons/md';
import {AiFillPicture} from 'react-icons/ai';
import {MdCollections} from 'react-icons/md';
import {ImPriceTags} from 'react-icons/im';
import {GrBitcoin} from 'react-icons/gr';
import {BsTelegram} from 'react-icons/bs';

export const HeroImage = () => {
    const { name, env, mintPrice } = appConfig();
    return (
        <div className="flex justify-center w-full h-128 bg-cover bg-[url('./assets/hero_bg.png')] rounded-xl" >
            <div className="w-3/4 md:w-1/2 p-3">
                <div className="flex flex-col justify-center items-center h-full">
                    <img src={icon512} className="w-48 h-48 rounded-xl drop-shadow-2xl" />
                    <div className="text-center text-3xl md:text-4xl font-bold text-pink-200 font-heading">Welcome to stablemusing.com</div>
                    <div className="text-center glow-yellow text-2xl md:text-3xl font-bold text-slate-100">Use text based prompts to generate images on telegram and pay for it with the awesome power of the Bitcoin Lightning Network.</div>
                </div>
            </div>
      </div>
    );
};

export const AddTelegram = () => {
    return (
        <div className="flex justify-center w-full mt-3" >
            <div className="p-3 border-2 rounded-lg hover:bg-slate-800 hover:cursor-pointer w-fit" 
                onClick={()=>window.open(`${appConfig().telegramBotLink}`,null,"_blank")}>
                <div className="flex flex-col justify-center items-center h-full">
                    <div className="flex flex-row items-center">
                        <BsTelegram className="mr-2 text-6xl"/> 
                        <span className="font-bold text-2xl">Click here to add the bot to your telegram now!</span>
                    </div>

                </div>
            </div>
      </div>
    );
};

export const ActionList = () => {
    const { name, env, mintPrice } = appConfig();
    return (
        <div className="p-6">
            <div className="grid gap-3 text-white grid-cols-1 sm:grid-cols-1 md:grid-cols-3">
                <div className="flex flex-row md:flex-col  justify-start rounded-md border-2 p-2">
                    <div className="items-baseline p-2 text-6xl"><GrBitcoin/></div>
                    <div className="items-baseline p-2 text-xl">Use any BOLT11 Lightning Network wallet to purchase "rendies" or just pay as you go.</div>
                </div>
                <div className="flex flex-row md:flex-col justify-start rounded-md border-2 p-2">
                    <div className="items-baseline p-2 text-6xl"><AiFillPicture/></div>
                    <div className="items-baseline p-2 text-xl">Generate any image simply by using natural language prompts. Share any image with permalinks.</div>
                </div>
                <div className="flex flex-row md:flex-col justify-start rounded-md border-2 p-2">
                    <div className="items-baseline p-2 text-6xl"><MdCollections/></div>
                    <div className="items-baseline p-2 text-xl"><span className="font-bold text-yellow-200 mr-1">Coming Soon.</span>Curate your collections and set attributes for your personal internet gallery.</div>
                </div>    
            </div>
        </div>
    );
};

export const Pricing = () => {
    return (
        <div className="m-2 flex flex-row justify-center">
            <div className="flex flex-col">
                <div className="flex flex-row justify-center items-center font-heading text-5xl">Pricing</div>
                <div className="flex flex-row justify-center items-center font-bold font-mono text-2xl m-1">1 RENDIE COSTS 1 SAT</div>
                <div className="flex flex-row justify-center items-center font-bold font-mono m-2 text-slate-400">*You will buy rendies and keep a balance on the bot. See terms of service for details.</div>  

                <div className="place-items-center grid gap-3 text-white grid-cols-1 md:grid-cols-3">
                    <div className="flex flex-col items-center text-center rounded-md p-3 bg-slate-700 w-[300] h-[300] md:w-[200] md:h-[400] lg:w-[300] lg:h-[300]">
                        <div className="text-2xl m-1">Basic Image Generation</div>
                        <div className="text-3xl font-mono text-yellow-300 m-1">500 rendies</div>
                        <div className="text-lg text-center"><span className="font-bold text-yellow-200 mr-1">All you need.</span> 
                         Text based prompts, 512 square image generation, basic inference and guidance.</div>
                    </div>
                    <div className="flex flex-col items-center text-center rounded-md p-3 bg-slate-700 w-[300] h-[300] md:w-[200] md:h-[400] lg:w-[300] lg:h-[300]">
                        <div className="text-2xl m-1">Advanced Generation</div>
                        <div className="text-3xl font-mono text-yellow-300 m-1">500-2000 rendies</div>
                        <div className="text-lg text-center"><span className="font-bold text-yellow-200 mr-1">Coming soon.</span> Provide advanced prompts, aspect ratios, resolutions, guidance and inference, image 
                            patterning and exclusion.</div>
                    </div>
                    <div className="flex flex-col items-center rounded-md p-3 bg-slate-700 w-[300] h-[300] md:w-[200] md:h-[400] lg:w-[300] lg:h-[300]">
                        <div className="text-2xl m-1 text-center">Discounts and Promo Codes</div>
                        <div className="text-4xl font-mono text-yellow-300 m-1">10%-50%</div>
                        <div className="text-lg text-center"><span className="font-bold text-yellow-200 mr-1">*Rewards for active uses.</span> We find tons of ways to air drop rendies to our loyal users.</div>
                    </div>
                </div>
                <div className="mt-4 flex flex-row justify-center">
                    <span className="text-purple-400 text-3xl">For a limited time, all new users receive a 5000 rendies airdrop!</span>
                </div>


            </div>

        </div>
    ); 
};