import React, {useEffect, useState} from "react"

import { appConfig } from "../env";




/**
 * component to render external links
 * @param {*} param0 
 */
export const ExternalLinksViewer = ({links, title="Important Links"}) => {


  
    /**
     * this handles the xApp and webapp cases for external links
     * 
     * @param {*} url 
     */
    let handleClickedLink = (url) => {
        console.log("clicked link in Web browser", url);
        window.open(url, "_blank");

    };
  
    let renderUrls = (links) => {
      return links.map((key, index) => (
          <li className="break-words" key={index}>
              <div onClick={()=>handleClickedLink(key.url)} className="text-xs font-bold text-blue-300 
                hover:underline cursor-pointer">{key.title}</div> 
          </li>
      ));
    };
  
    return (
      <div className="flex flex-col">
          <ul className="mb-0 list-none space-y-1">
            {renderUrls(links)}
          </ul>
      </div>
    );
};
  

const Footer = () => {

    //${baseUrl}/static/tos.html
    let [apiInfo, setApiInfo] = useState();
    let [config, setConfig] = useState(appConfig());


    const externalLinks = [
        { title: 'Terms of Service', url: `${config.appBaseUrl}/static/tos.html` },
        { title: 'Privacy Policy', url: `${config.appBaseUrl}/static/privacy.html` },
        { title: 'Telegram Group', url: 'https://t.me/+ns-InEgdSFRhODJh' },
        { title: 'Support', url: 'https://rapaygo.freshdesk.com/support/home' },
        { title: 'Stable Diffusion Prompt Builder', url: 'https://promptomania.com/stable-diffusion-prompt-builder/' },
    ]


    return (
        <>
        <footer className="mt-auto text-white bg-slate-900">
            <div className="p-6 w-full">
                <div className="grid gap-x-0.8 grid-cols-1 md:grid-cols-2"> 

                    <div className="mb-6 sm:w-full md:w-1/2">
                         <h5 className="text-slate-300 font-heading text-2xl">
                            <span className="text-yellow-200">stablemusing</span>
                            <span className="text-purple-300">.com</span></h5>
                         <div className="text-slate-500">by rapaygo LLC</div>
                         <div>Use text based prompts to generate images on telegram and pay for it with the awesome power of the Bitcoin Lightning Network.</div>
                     </div>
                    <div className="mb-6">
                        <h5 className="mb-2.5 font-heading uppercase text-slate-300">Links</h5>
                        <ExternalLinksViewer links={externalLinks}/>
                    </div>
                </div>
            </div>     
            <div className="p-2 text-center">copyright 2022,2023 by rapaygo LLC.</div>     
        </footer>
        </>

    );
  };
  
export default Footer;
