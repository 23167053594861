import deployment from './deployment.json';

// S3_IMAGES_PATH="botdata/img"
// S3_PROMPT_PATH="botdata/prompt"

export const appConfig = () => {
    const config = {
        prd: {
            name: 'smbot',
            appBaseUrl: 'https://stablemusing.com',
            staticBaseUrl: 'https://stablemusing.com',
            imagePath: 'botdata/img',
            promptPath: 'botdata/prompt',
            telegramBotLink: 'https://t.me/stable_musing_bot',
            telegramGroupLink: 'https://t.me/+aA2_9cDkHh1mY2U5',
            
        },
        dev: {
            name: 'smbot',
            appBaseUrl: 'https://dev.stablemusing.com',
            staticBaseUrl: 'https://dev.stablemusing.com',
            imagePath: 'botdata/img',
            promptPath: 'botdata/prompt',     
            telegramBotLink: 'https://t.me/stable_musing_dev_bot',   
            telegramGroupLink: 'https://t.me/+H_bMumg02tszMTVh',
        },
        local: {
            name: 'smbot',
            appBaseUrl: 'http://localhost:3010',
            staticBaseUrl: 'http://dev.stablemusing.com.s3-website-us-west-2.amazonaws.com',
            imagePath: 'botdata/img',
            promptPath: 'botdata/prompt',
            telegramBotLink: 'https://t.me/stable_musing_dev_bot',
            telegramGroupLink: 'https://t.me/+H_bMumg02tszMTVh',
        }
    };
    return config[deployment.env];
};


//https://gateway.pinata.cloud/ipfs/QmeKBgV5pqhXY2Yj2F4BbJJjEzUoh3djFyNv1XTp3UVx5k
export const apiConfig = () => {
    const config = {
        prd: {
            apiBaseUrl: 'https://api.ibisx.com/v1',
            webBaseUrl: 'https://ibisx.com',
            wsBaseUrl: 'wss://ws.ibisx.com',
            pinataGateway: 'https://gateway.pinata.cloud/ipfs',
        },
        dev: {
            apiBaseUrl: 'http://localhost:3000/v1',
            webBaseUrl: 'http://localhost:3001',
            wsBaseUrl: 'ws://localhost:3002',
            pinataGateway: 'https://gateway.pinata.cloud/ipfs',
        },
        local: {
            apiBaseUrl: 'http://localhost:5000',
            webBaseUrl: 'http://localhost:1234',
            wsBaseUrl: 'ws://localhost:5002',
            pinataGateway: 'https://gateway.pinata.cloud/ipfs',
        },
    };
    return config[deployment.env];
};