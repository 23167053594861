import React, {useEffect, useState } from "react"

import Page from "../components/Page";
import {HeroImage, ActionList, Pricing, AddTelegram } from "../components/HomeComponents";

export const Home = () => {
    return (
        <Page>
            <div className="p-6 flex flex-col">
                {/* <div className="text-4xl w-full flex flex-row m-3">stablemusing.com</div> */}
                <div>
                    <HeroImage/>
                </div>
                <div>
                    <AddTelegram/>
                </div>
                <div>
                    <ActionList/>
                </div>
                <div>
                    <Pricing/>
                </div>
                <div className="w-full text-center mt-3">All lightning network payments powered by <button className="underline hover:cursor-pointer hover:text-fuchsia-400" onClick={()=>window.open('https://rapaygo.com',"_new")}>https://rapaygo.com</button></div>
            </div>
        </Page>

    );

}