import React, {useEffect, useState} from "react"

import Header from "./Header"
import Footer from "./Footer"
// import Sidebar from "./Sidebar"

const Page = ({
    withSidenav=false,
    xumm=null,
    runtime=null,
    children,
  }) => {

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="flex">
          <Header/>
        </div>
        <div id="main" className="flex flex-grow flex-col md:flex-row">
            <div className="flex-grow">{children}</div>
        </div>
        <div id="footer" className="p-0 bg-black color-white">
         <Footer/>
        </div>
      </div>   

    </>
  );
};
  
export default Page