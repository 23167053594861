import React, {useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import {appConfig} from '../env';
import Page from "../components/Page";
import {BsTelegram} from 'react-icons/bs';
import {AiFillPicture} from 'react-icons/ai';
import {FaCoffee} from 'react-icons/fa';
import {FaTshirt} from 'react-icons/fa';



export const MerchItems = () => {
    const { name, env, mintPrice } = appConfig();
    return (
        <div className="p-2 flex flex-col justify-center w-[300]">
            <div className="p-3 text-2xl text-center w-full">THORBUYS.COM</div>
            <div className="grid gap-3 text-white grid-cols-1 sm:grid-cols-1 md:grid-cols-3">
                <div onClick={()=>window.open(`https://thorbuys.com/product/stable-musing-custom-t-shirt-made-in-usa/`,"_new")} 
                className="flex flex-row md:flex-col  justify-start rounded-md border-2 p-2 hover:bg-slate-500 hover:cursor-pointer">
                    <div className="items-baseline p-2 text-4xl"><FaTshirt/></div>
                    <div className="items-baseline p-2 text-xs uppercase">Make A T-SHIRT From This</div>
                </div>
                <div onClick={()=>window.open(`https://thorbuys.com/product/stable-musing-custom-image-canvas-print-12x12-made-in-usa/`,"_new")} 
                className="flex flex-row md:flex-col justify-start rounded-md border-2 p-2 hover:bg-slate-500 hover:cursor-pointer">
                    <div className="items-baseline p-2 text-4xl"><AiFillPicture/></div>
                    <div className="items-baseline p-2 text-xs uppercase">Make Hanging Canvas From THis</div>
                </div>
                <div onClick={()=>window.open(`https://thorbuys.com/product/stable-musing-custom-image-custom-ceramic-mug-made-in-usa/`,"_new")} 
                className="flex flex-row md:flex-col justify-start rounded-md border-2 p-2 hover:bg-slate-500 hover:cursor-pointer">
                    <div className="items-baseline p-2 text-4xl"><FaCoffee/></div>
                    <div className="items-baseline p-2 text-xs uppercase">Make a Custom Mug From This</div>
                </div>    
            </div>
        </div>
    );
};

export const AddTelegram = () => {
    return (
        <div className="flex justify-center w-fit h-128 m-6" >
            <div className="w-fit p-3 border-2 rounded-lg hover:bg-slate-800 hover:cursor-pointer" 
                onClick={()=>window.open(`${appConfig().telegramBotLink}`,null,"_blank")}>
                <div className="flex flex-col justify-center items-center h-full">
                    <div className="flex flex-row items-center">
                        <BsTelegram className="mr-2 text-2xl"/> 
                        <span className="font-bold text-lg">Click here to add the bot to your telegram now!</span>
                    </div>

                </div>
            </div>
      </div>
    );
};

export const Musing = () => {
    const { username, imageid } = useParams();
    const [imageSrc, setImageSrc] = useState(null);
    const [imageInfoUrl, setImageInfoUrl] = useState(null);
    const [imageInfo, setImageInfo] = useState(null);

    useEffect(() => {   
        let config = appConfig();
        setImageSrc(`${config.staticBaseUrl}/${config.imagePath}/${username}/${imageid}.png`);
        setImageInfoUrl(`${config.staticBaseUrl}/${config.promptPath}/${username}/${imageid}.json`);

    }, [username, imageid]);

    useEffect( () => {
        if(imageInfoUrl) {
            
            let headersList = {
                "Accept": "*/*",
                "User-Agent": "IbisX fetchJS Client"
            }
            
            fetch(imageInfoUrl, { 
                method: "GET",
                headers: headersList
            })
            .then(response => response.json())
            .then(data => {
                // Do something with the data
                console.log(data);
                setImageInfo(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });           
            
        }
    }, [imageInfoUrl]);

    return (
        <Page className="p-6">
            <div className="flex flex-row w-full">
                <div className="flex flex-col items-center w-full mt-3">
                    <div className="text-3xl">Musing</div>
                    <div className="text-xl font-mono">{username} {imageid}</div>
                    <img src={imageSrc} alt="musing" className="rounded-lg m-3" />
                    <div className="flex flex-row font-mono">
                        <div className="text-lg w-[300] md:w-[500] text-center mt-3">{imageInfo?.prompt}</div>
                    </div>
                    <div className="flex flex-col rounded-lg mt-3 bg-slate-700 font-mono p-3">
                        <div>num_inference_steps: {imageInfo?.num_inference_steps}</div>
                        <div>guidance_scale: {imageInfo?.guidance_scale}</div>
                        <div>seed: {imageInfo?.seed}</div>
                    </div>
                    <AddTelegram/>
                    {/* <MerchItems/> */}
                </div>
            </div>

        </Page>

    );

}