import React, {useEffect, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Axios from 'axios';

import { Home } from "./pages/Home";

import "./styles.css";
import { Musing } from "./pages/Musing";

Axios.defaults.withCredentials = false; 

export function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>
            <Route path="musing/:username/:imageid" element={
              <Musing/>
            } />  
            <Route path="/" element={
              <Home/>
            } />  
        </Routes>
    </BrowserRouter>
    </>
  );
}