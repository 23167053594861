import React, {useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import imgIcon from '../assets/favicon-64x64.png';

const Header = () => {

    const navigate = useNavigate();

    return (
      <div className="w-full">        
            <nav className="flex flex-col md:flex-row items-center justify-between bg-slate-700 p-1">
                <div className="flex w-full md:w-1/2 ml-1 mt-1">
                    <div className="hover:cursor-pointer flex flex-row ml-2 mt-2 items-center font-semibold text-3xl font-heading tracking-tight
                         text-white" onClick={()=>navigate('/')}>
                            <img src={imgIcon} className="w-12 h-12 mr-2 rounded-full" alt="logo" />
                        <span className="text-yellow-200">stablemusing</span><span className="text-purple-300">.com</span> 
                    </div>
                </div>
                {/* <div className="flex flex-row md:w-1/2 justify-end w-full">
                        <div className="flex flex-row">HEYNOW</div>
                </div>    */}
            </nav>
         
      </div>
    );
};

const dropdownStyle = {
    zIndex: 50
};
  
export default Header